import { createStore } from "vuex";
import { IProduct } from "@/interfaces/IProduct";

interface State {
  loading: boolean;
  openingProductOnDialog: IProduct | null;
  shoppingCartList: IProduct[];
  productDialogOpen: boolean;
}

export default createStore({
  state: {
    loading: false,
    productDialogOpen: false,
    shoppingCartList: [],
    openingProductOnDialog: null,
  } as State,
  mutations: {
    SET_LOADING: (state, loading) => (state.loading = loading),

    ADD_TO_CART: (state, product: IProduct) => {
      state.shoppingCartList = [
        ...state.shoppingCartList,
        { ...product, quantity: 1 },
      ];
    },

    REMOVE_FROM_CART: (state, productId: number) => {
      state.shoppingCartList = state.shoppingCartList.filter(
        (product: IProduct) => product.id !== productId
      );
    },

    UPDATE_QUANTITY: (state, { existingProduct, existingProductIndex }) => {
      const shoppingCartList = state.shoppingCartList;
      shoppingCartList[existingProductIndex] = existingProduct;
      state.shoppingCartList = [...shoppingCartList];
    },

    TOGGLE_PRODUCT_DIALOG: state => {
      state.productDialogOpen = !state.productDialogOpen;
    },

    SET_OPENING_PRODUCT: (state, product: IProduct) => {
      state.openingProductOnDialog = product;
    },

    RESET_SHOPPING_CART_LIST: state => {
      state.shoppingCartList = [];
    },
  },
  actions: {
    toggleLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },

    updateQuantity(
      { commit, state },
      { productId, type }: { productId: number; type: string }
    ) {
      const existingProductIndex = state.shoppingCartList.findIndex(
        p => p.id === productId
      );
      if (existingProductIndex !== undefined) {
        const existingProduct = state.shoppingCartList[existingProductIndex];
        if (existingProduct.quantity) {
          if (type === "+") {
            existingProduct.quantity += 1;
          } else {
            existingProduct.quantity -= 1;
            if (existingProduct.quantity === 0) {
              commit("REMOVE_FROM_CART", existingProduct.id);
              return;
            }
          }
        }
        commit("UPDATE_QUANTITY", { existingProduct, existingProductIndex });
      }
    },

    resetShoppingCartList({ commit }) {
      commit("RESET_SHOPPING_CART_LIST");
    },
  },
  modules: {},
});
