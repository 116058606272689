import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import MainLayout from "../layouts/MainLayout.vue";
import Home from "../views/Home.vue";
import Browse from "../views/Browse.vue";
import Checkout from "../views/Checkout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "MainLayout",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/browse",
        name: "Browse",
        component: Browse,
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: Checkout,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
