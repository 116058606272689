import httpClient from "@/utils/http-client";
import { ICategory } from "@/interfaces/ICategory";

export const CategoryApi = {
  async getCategories(): Promise<ICategory[]> {
    const response = await httpClient({
      url: "/categories",
      method: "GET",
    });

    return response.data;
  },
};
