export interface ShippingInformation {
  customerName: string;
  phoneNumber: string;
  address: string;
  notes: string;
}

export interface IProductComponent {
  __component?: string;
  product: number;
  quantity: number;
  subTotal: number;
}

export class ProductComponent implements IProductComponent {
  __component?: string = "product.product";
  product: number;
  quantity: number;
  subTotal: number;

  constructor({ product, quantity, subTotal }: IProductComponent) {
    this.product = product;
    this.quantity = quantity;
    this.subTotal = subTotal;
  }
}

export interface IOrder extends ShippingInformation {
  total: number | null;
  products: ProductComponent[];
}
