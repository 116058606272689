import httpClient from "@/utils/http-client";

import { IProduct } from "@/interfaces/IProduct";
import { ProductFilter } from "@/interfaces/common/IFilter";

export const ProductApi = {
  async getProducts(params?: ProductFilter): Promise<IProduct[]> {
    const response = await httpClient({
      url: "/products",
      method: "GET",
      params,
    });

    return response.data;
  },

  async getProduct(id: number): Promise<IProduct> {
    const response = await httpClient({
      url: `/products/${id}`,
      method: "GET",
    });

    return response.data;
  },

  async getProductCount(): Promise<number> {
    const response = await httpClient({
      url: `/products/count`,
      method: "GET",
    });

    return response.data;
  },
};
