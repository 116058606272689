import httpClient from "@/utils/http-client";
import { IOrder } from "@/interfaces/IOrder";

export const OrderApi = {
  async createOrder(order: IOrder): Promise<any> {
    const response = await httpClient({
      url: "/orders",
      method: "POST",
      data: order,
    });

    return response.data;
  },
};
