import { onMounted, ref } from "vue";

import { ProductApi } from "@/api/product";
import { IProduct } from "@/interfaces/IProduct";
import { ProductFilter } from "@/interfaces/common/IFilter";
import { loadingWrapper } from "@/helpers/loading-wrapper";

export const useFetchProducts = (params?: ProductFilter) => {
  const page = ref(1);
  const lastPage = ref(false);
  const products = ref<IProduct[]>([]);

  const resetPageMeta = () => {
    lastPage.value = false;
    page.value = 1;
  };

  const refetchProducts = async (params?: any) => {
    products.value = await ProductApi.getProducts(
      new ProductFilter({
        ...params,
      })
    );
  };

  const infiniteSrollFetchMore = async (params?: any) => {
    if (lastPage.value) return;
    page.value = page.value + 1;
    loadingWrapper(async () => {
      const additionalProducts = await ProductApi.getProducts(
        new ProductFilter({
          ...params,
          page: page.value,
          pageSize: 12,
        })
      );
      additionalProducts.length < 12 && (lastPage.value = true);
      products.value.push(...additionalProducts);
    });
  };

  onMounted(async () => {
    loadingWrapper(async () => {
      products.value = await ProductApi.getProducts(
        new ProductFilter({
          ...params,
          page: page.value,
          pageSize: 12,
        })
      );
    });
  });

  return {
    products,
    resetPageMeta,
    infiniteSrollFetchMore,
    refetchProducts,
  };
};
