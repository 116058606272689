import { IPagination } from "./IPagination";
import { StrapiParams } from "./IStrapiParams";

interface IProductFilter {
  name?: string;
  category?: number; // id of category
}

export class ProductFilter extends StrapiParams {
  name_contains?: string;
  category?: number;

  constructor({
    page = 1,
    pageSize = 12,
    name,
    category,
  }: IPagination & IProductFilter) {
    super({ page, pageSize });
    this.name_contains = name;
    this.category = category;
  }
}
