import store from "../store";

export const loadingWrapper = async (callback?: any): Promise<any> => {
  try {
    store.dispatch("toggleLoading", true);
    return callback && (await callback());
  } catch (error) {
    console.error((error as Error).message);
    return null;
  } finally {
    setTimeout(() => {
      store.dispatch("toggleLoading", false);
    }, 750);
  }
};
