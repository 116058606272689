import { IPagination } from "./IPagination";

export class StrapiParams {
  _start: number;
  _limit: number;

  constructor({ page = 1, pageSize = 12 }: IPagination) {
    this._start = (page - 1) * pageSize;
    this._limit = pageSize;
  }
}
